import React, { useEffect, useState } from 'react';
import axios from 'axios';
import JSZip from 'jszip';
import { useLocation, useNavigate } from 'react-router-dom';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { useKeycloak } from '@react-keycloak/web';
import ExportModal from './ExportModal';
import ChevronBottomIcon from '../assets/icons/chevron-bottom.svg';
import { ReactCompareSlider, ReactCompareSliderHandle, ReactCompareSliderImage } from 'react-compare-slider';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

interface InputFieldProps {
  label: string;
  placeholder: string;
  type?: string;
}

const InputField: React.FC<InputFieldProps> = ({ label, placeholder, type = "text" }) => (
  <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full pr-5">
    <div className="flex flex-col grow ml-10 text-lg font-medium tracking-normal max-md:mt-10 max-md:max-w-full">
      <div className="text-neutral-500 max-md:max-w-full">{label}</div>
      <input
        type={type}
        placeholder={placeholder}
        className="px-4 py-2.5 mt-4 rounded-lg border border-solid border-zinc-300 text-neutral-400 w-full max-md:pr-5 max-md:max-w-full"
      />
    </div>
  </div>
);

interface SelectFieldProps {
  label: string;
  options: string[];
}

const SelectField: React.FC<SelectFieldProps> = ({ label, options }) => (
  <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
    <div className="flex flex-col mt-auto mr-10 h-auto text-lg font-medium tracking-normal grow-0 max-md:mt-10 max-md:max-w-full">
      <div className="text-neutral-500 max-md:max-w-full">{label}</div>
      <select className="flex gap-5 px-4 py-2.5 mt-4 rounded-lg border border-solid border-zinc-300 text-neutral-400 w-full max-md:flex-wrap max-md:max-w-full">
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  </div>
);

const DocumentForm: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [dragActive, setDragActive] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [images, setImages] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<string>('Add New Document');

  const location = useLocation();
  const submissionKey = location.state?.submissionKey || 'submission1';
  const stirlingPdfBaseUrl = process.env.REACT_APP_STIRLING_PDF_BASE_URL;
  const awsRegion = process.env.REACT_APP_AWS_REGION;
  const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
  const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  const awsS3Bucket = process.env.REACT_APP_AWS_S3_BUCKET;
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const [currentSubmission, setCurrentSubmission] = useState('');
  const [currentPage, setCurrentPage] = useState('');
  const [compareSubmission, setCompareSubmission] = useState('');
  const [comparePage, setComparePage] = useState('');
  const [compareSubmissions, setCompareSubmissions] = useState<string[]>([]);
  const [comparePages, setComparePages] = useState<string[]>([]);
  const [leftImage, setLeftImage] = useState('');
  const [rightImage, setRightImage] = useState('');
  const [loading, setLoading] = useState(false);

  // const [loaded, setLoaded] = React.useState(0);
  // const imagesStyle = {
  //   opacity: loaded === 2 ? 1 : 0,
  //   transition: 'opacity 1s 0.5s ease-in-out'
  // };

  type LabelType = 'submission' | 'page';

  const openExportModal = () => setIsExportModalOpen(true);
  const closeExportModal = () => setIsExportModalOpen(false);

  const tabs = [
    { name: 'Add New Document' },
    { name: 'Uploaded Document' },
    { name: 'Requested to Upload' },
    { name: 'Import Document' },
    { name: 'Export Document' },
    { name: 'Compare Submissions' },
  ];  

  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const userId = keycloak.tokenParsed?.sub;

  useEffect(() => {
    if (submissionKey) {
      console.log(`Document is coming from ${submissionKey}`);
      // Additional logic if needed
    }
  }, [submissionKey]);

  // Initialize the S3 client
  const s3Client = new S3Client({
    region: awsRegion,
    credentials: {
      accessKeyId: awsAccessKeyId!,
      secretAccessKey: awsSecretAccessKey!,
    },
  });

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  const savePDFToLocalStorage = async (pdfFile: File, key: string) => {
    const formData = new FormData();
    formData.append('fileInput', pdfFile);
    formData.append('pageNumbers', "all");
    
    try {
      const response = await axios.post(stirlingPdfBaseUrl+'/api/v1/general/split-pages', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        responseType: 'arraybuffer'  // Specify the response type as arraybuffer
      });

      const zipArrayBuffer = response.data;
      console.log('Received zip file:', zipArrayBuffer);

      const zip = new JSZip();
      const zipData = await zip.loadAsync(zipArrayBuffer);

      const pdfPages: { [pageNo: string]: string } = {};
      for (const [filename, file] of Object.entries(zipData.files)) {
        if (!file.dir && filename.endsWith('.pdf')) {
          const pdfArrayBuffer = await file.async('arraybuffer');
          const base64PDF = btoa(
            new Uint8Array(pdfArrayBuffer)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          const pageNo = filename.replace('.pdf', '');
          pdfPages[pageNo] = `data:application/pdf;base64,${base64PDF}`;
        }
      }

      console.log('Extracted PDF pages:', pdfPages);

      // Now proceed to save the new PDF pages
      Object.entries(pdfPages).forEach(([pageNo, base64PDF]) => {
        const pageNumber = pageNo.split('_').pop() || pageNo;
        localStorage.setItem(`${key}-page${pageNumber}`, base64PDF);
        console.log(`PDF page ${pageNumber} saved as ${key}-page${pageNumber} in localStorage`);
      });
    } catch (error) {
      console.error('Error splitting PDF:', error);
      // You might want to add more detailed error logging here
      if (error instanceof Error) {
        console.error('Error message:', error.message);
        console.error('Error stack:', error.stack);
      }
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const uploadedFile = e.target.files[0];
      setFile(uploadedFile);
      // if (submissionKey) {
      //   savePDFToLocalStorage(uploadedFile, submissionKey);
      // }
    }
  };

  const clearExcalidrawData = () => {
    const keysToRemove = [];
    for (let i = 1; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && submissionKey && key.startsWith(`${submissionKey}_excalidrawData_`)) {
        keysToRemove.push(key);
      }
    }
    keysToRemove.forEach((key) => localStorage.removeItem(key));
    console.log('Excalidraw data has been cleared from localStorage for submission:', submissionKey);
  };  

  const handleZipFile = async (zipBlob: Blob) => {
    try {
      const zip = new JSZip();
      const zipData = await zip.loadAsync(zipBlob);
  
      const extractedImages: string[] = [];
      let pageIndex = 1; // Initialize page index
      for (const filename of Object.keys(zipData.files)) {
        const file = zipData.files[filename];
  
        if (!file.dir && /\.(png|jpe?g|gif)$/i.test(filename)) {
          const imageBlob = await file.async('blob');
          
          // Upload image to S3
          const s3Key = `gollab/documents/${submissionKey}-page${pageIndex}.png`;
          const uploadParams = {
            Bucket: awsS3Bucket!, // Ensure this is correctly set
            Key: s3Key,
            Body: imageBlob,
            ContentType: 'image/png', // Adjust the content type as needed
          };

          console.log('Uploading to S3 with params:', uploadParams);

          const response = await s3Client.send(new PutObjectCommand(uploadParams));
          console.log('S3 upload response:', response);

          const s3Url = `https://${awsS3Bucket}.s3.${awsRegion}.amazonaws.com/${s3Key}`;
          console.log(s3Url);
          
          const base64Image = await blobToBase64(imageBlob, 'image/png');
          extractedImages.push(base64Image);

          localStorage.setItem(`${submissionKey}-page${pageIndex}`, s3Url);

          pageIndex++; // Increment page index for the next image
        }
      }
  
      console.log(extractedImages);
      // here we are clearing the localStorage in order to prevent the previous elements in new pdf images.
      clearExcalidrawData();
      // if (submissionKey) {
      //   localStorage.setItem(submissionKey+'pdfImages', JSON.stringify(extractedImages));
      // }
      
    } catch (error) {
      console.error('Error extracting images from ZIP file:', error);
    }
  };
  
  // Helper function to convert Blob to base64 with specified MIME type
  const blobToBase64 = (blob: Blob, mimeType: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result as string;
        // Ensure the base64 string has the correct MIME type
        const base64Image = `data:${mimeType};base64,${base64Data.split(',')[1]}`;
        resolve(base64Image);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };  

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    if (!file) {
      console.error('No file selected');
      return;
    }

    if (!userId) {
      console.error('User ID not found');
      return;
    }
  
    setIsProcessing(true);
  
    try {

      const s3Key = `gollab/documents/${userId}/${submissionKey}.pdf`;
      const uploadParams = {
        Bucket: awsS3Bucket!, // Ensure this is correctly set
        Key: s3Key,
        Body: file,
        ContentType: file.type, // Adjust the content type as needed
      };

      console.log('Uploading to S3 with params:', uploadParams);

      const response = await s3Client.send(new PutObjectCommand(uploadParams));
      console.log('S3 upload response:', response);

      // Upload the PDF to StirlingPDF
      const formData = new FormData();
      formData.append('fileInput', file);
      formData.append('imageFormat', 'jpeg');
      formData.append('singleOrMultiple', 'multiple');
      formData.append('colorType', 'color');
      formData.append('dpi', '200');
  
      try {
        const imageResponse = await axios.post(stirlingPdfBaseUrl+'/api/v1/convert/pdf/img', formData, {
          withCredentials: false,
          responseType: 'blob',
          headers: {
            // 'Content-Type': 'application/json',           
          }
        });
  
        if (imageResponse.statusText !== 'OK') {
          console.error('HTTP error', imageResponse.status);
          return;
        }        
      
        const imageBlob = await imageResponse.data;       
        if (imageBlob.size === 0) {
          console.error('Received empty image blob');
          return;
        }
      
        // Assuming handleZipFile processes the image blob or URL
        await handleZipFile(imageBlob);
  
        // Redirect to ExcalidrawCanvas after saving images, passing the submissionKey
        navigate('/playground', { state: { imageIndex: 0, submissionKey } });
  
      } catch (error) {
        console.error('Error converting PDF to image:', error);
      }      
  
    } catch (error) {
      console.error('Error processing PDF:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const formatLabel = (type: LabelType, value: string | number): string => {
    if (!value) return '';
    if (type === 'submission') {
      return `Submission ${String(value).replace('submission', '')}`;
    }
    if (type === 'page') {
      return `Page ${String(value).replace('page', '')}`;
    }
    return '';
  };

  useEffect(() => {
    const storedSubmission = localStorage.getItem('submission');
    const storedPage = localStorage.getItem('page');
    
    if (storedSubmission) {
      setCurrentSubmission(storedSubmission);
    }
    if (storedPage) {
      setCurrentPage(storedPage);
    }

    const submissions = [];
    let i = 1;
    while (localStorage.getItem(`submission${i}_excalidrawData_0`)) {
      submissions.push(`submission${i}`);
      i++;
    }
    setCompareSubmissions(submissions);
  }, []);

  useEffect(() => {
    if (compareSubmission) {
      const pages: string[] = [];
      let i = 0;
      while (localStorage.getItem(`${compareSubmission}_excalidrawData_${i}`)) {
        pages.push(`page${i + 1}`);  
        i++;
      }
      setComparePages(pages);
    }
  }, [compareSubmission]);

  const fetchImageUrl = async (submission: string, page: string): Promise<string> => {
    if (!submission || !page) return '';
    setLoading(true);
    try {
      const url = localStorage.getItem(`${submission}-${page}`); // Simulate retrieval
      return url || '';
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const updateImages = async () => {
      const leftUrl = await fetchImageUrl(currentSubmission, currentPage);
      const rightUrl = await fetchImageUrl(compareSubmission, comparePage);
      setLeftImage(leftUrl);
      setRightImage(rightUrl);
    };

    updateImages();
  }, [currentSubmission, currentPage, compareSubmission, comparePage]);

  const handleSelectLeft = () => alert("Selected Left Image");
  const handleSelectRight = () => alert("Selected Right Image");
  const handleClose = () => alert("Slider Closed");

  const AddNewDocument: React.FC = () => (
    <form className="flex flex-col items-start" onSubmit={handleSubmit}>
      <div className="self-stretch mt-7 w-full max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <InputField label="Document name" placeholder="Enter document name" />
          <SelectField label="Document type" options={["Select document type"]} />
        </div>
      </div>
      <div className="self-stretch mt-7 w-full max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <InputField label="Document category" placeholder="Enter document category" />
          <SelectField label="Document needed" options={["Mandatory"]} />
        </div>
      </div>
      <div
        className={`box-border flex relative flex-col shrink-0 ml-10 ${dragActive ? 'border-dashed border-emerald-500' : 'border-emerald-800'}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="mt-7 text-lg font-medium tracking-normal text-neutral-500 max-md:mt-10 max-md:max-w-full">
          Upload
        </div>
        <div className="flex justify-center items-center py-6 px-10 mt-4 mr-auto max-w-full rounded-2xl border border-dashed bg-[#F9FBFC] w-full max-md:px-5">
          <div className="flex gap-4 items-center max-md:flex-wrap">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/9c21eaadb9f35f9972215fa476f1f526a81fbb8c48d30d182bb4cf20b0521ec0?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&"
              alt=""
              className="shrink-0 self-stretch aspect-[0.81] w-[34px]"
            />
            <div className="self-stretch my-auto text-xl font-medium text-center text-emerald-800">
              {file ? file.name : 'Drag and Drop documents here or'}
            </div>
            <label className="self-stretch px-5 py-3 my-auto text-base font-semibold text-white whitespace-nowrap bg-emerald-600 rounded-md max-md:px-5 cursor-pointer">
              Upload
              <input type="file" className="hidden" onChange={handleFileUpload} />
            </label>
          </div>
        </div>
      </div>
      <div className="flex gap-5 justify-between mt-7 ml-10 text-lg font-medium tracking-normal">
        <button type="submit"  disabled={isProcessing} className="self-center px-5 py-3 h-auto text-white bg-emerald-600 rounded-lg max-md:px-5">
        {isProcessing ? 'Processing...' : 'Add Document'}
        </button>
        <button type="reset" className="self-center px-5 py-3 h-auto whitespace-nowrap bg-white rounded-lg border border-solid border-zinc-300 text-neutral-700 max-md:px-5" onClick={() => setFile(null)}>
          Reset
        </button>
      </div>
    </form>
  );

  const ExportDocument: React.FC = () => (
    <div className="flex flex-col">
      {/* Your existing Export Document content goes here */}
      <div className="flex flex-col items-start mt-8 ml-8 max-w-full text-neutral-500 w-[1074px]">
          <div className="text-xl font-bold text-neutral-700">
            Export document or drawing
          </div>
          <div className="self-stretch mt-4 text-lg font-medium leading-7 max-md:max-w-full">
            Seamlessly export your Gollab documents or drawings to external
            formats like PDFs or other file types. Share your work beyond Gollab
            with ease, ensuring compatibility and accessibility for all
            collaborators.&quot;
          </div>
          <div className="flex gap-2 items-start mt-9 text-sm font-semibold leading-none">
            <div className="flex items-center bg-white rounded border border-solid border-zinc-300">
              <div className="flex gap-3 justify-center items-center self-stretch px-4 py-3 my-auto">
                <div className="self-stretch my-auto">Document type: All</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/daf174a7aa6f4953b5da9876588ac6f2/94d1ef0e94cf0eb7ec52ac71d211d9a7f646ea3ea236d3f41657c680fb345bca?apiKey=daf174a7aa6f4953b5da9876588ac6f2&"
                  className="object-contain shrink-0 self-stretch my-auto w-2 aspect-[2]"
                />
              </div>
            </div>
            <div className="flex items-center bg-white rounded border border-solid border-zinc-300">
              <div className="flex gap-3 justify-center items-center self-stretch px-4 py-3 my-auto">
                <div className="self-stretch my-auto">
                  Document category: All
                </div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/daf174a7aa6f4953b5da9876588ac6f2/94d1ef0e94cf0eb7ec52ac71d211d9a7f646ea3ea236d3f41657c680fb345bca?apiKey=daf174a7aa6f4953b5da9876588ac6f2&"
                  className="object-contain shrink-0 self-stretch my-auto w-2 aspect-[2]"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-8 font-semibold max-md:mr-0.5 max-md:max-w-full">
          <div className="flex flex-col max-w-full w-[1600px]">
            <div className="flex flex-col w-full rounded-none max-w-[1600px] max-md:max-w-full">
              <div className="flex flex-wrap gap-5 justify-between px-12 py-3 w-full border-t border-b bg-slate-50 border-zinc-300 max-md:px-5 max-md:max-w-full">
                <div className="my-auto text-md tracking-normal text-neutral-700">
                  Submission 1 (6 documents)
                </div>
                <div className="flex items-center text-sm leading-none bg-white rounded border border-solid border-zinc-300 text-neutral-500">
                  <div className="flex gap-3 justify-center items-center self-stretch px-4 py-3 my-auto">
                    <div className="self-stretch my-auto">Export all </div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/daf174a7aa6f4953b5da9876588ac6f2/94d1ef0e94cf0eb7ec52ac71d211d9a7f646ea3ea236d3f41657c680fb345bca?apiKey=daf174a7aa6f4953b5da9876588ac6f2&"
                      className="object-contain shrink-0 self-stretch my-auto w-2 aspect-[2]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col max-w-full text-md text-neutral-700 w-[1600px]">
            <div className="flex flex-col w-full rounded-none max-w-[1600px] max-md:max-w-full">
              <div className="flex flex-wrap gap-5 justify-between px-12 py-3 w-full border-t border-b bg-slate-50 border-zinc-300 max-md:px-5 max-md:max-w-full">
                <div className="flex gap-5 my-auto tracking-normal">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/daf174a7aa6f4953b5da9876588ac6f2/7c7d51099618794330519ea863998eaf2f3a12179a5839f7962aca110aebf4d3?apiKey=daf174a7aa6f4953b5da9876588ac6f2&"
                    className="object-contain shrink-0 w-8 aspect-[1.1]"
                  />
                  <div className="my-auto">Traffic plan</div>
                </div>
                <div className="flex gap-10 items-center whitespace-nowrap max-md:max-w-full">
                  <div className="self-stretch my-auto tracking-normal">
                    PDF
                  </div>
                  <div className="self-stretch my-auto tracking-normal">
                    Drawing
                  </div>
                  <div className="flex items-center self-stretch text-sm leading-none bg-white rounded border border-solid border-zinc-300 text-neutral-500 cursor-pointer" onClick={openExportModal}>
                    <div className="flex gap-3 justify-center items-center self-stretch px-4 py-3 my-auto">
                      <div className="self-stretch my-auto">Export</div>
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/daf174a7aa6f4953b5da9876588ac6f2/94d1ef0e94cf0eb7ec52ac71d211d9a7f646ea3ea236d3f41657c680fb345bca?apiKey=daf174a7aa6f4953b5da9876588ac6f2&"
                        className="object-contain shrink-0 self-stretch my-auto w-2 aspect-[2]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col max-w-full text-md text-neutral-700 w-[1600px]">
            <div className="flex flex-col w-full rounded-none max-w-[1600px] max-md:max-w-full">
              <div className="flex flex-wrap gap-5 justify-between px-12 py-3 w-full border-t border-b bg-slate-50 border-zinc-300 max-md:px-5 max-md:max-w-full">
                <div className="flex gap-5 my-auto tracking-normal">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/daf174a7aa6f4953b5da9876588ac6f2/7c7d51099618794330519ea863998eaf2f3a12179a5839f7962aca110aebf4d3?apiKey=daf174a7aa6f4953b5da9876588ac6f2&"
                    className="object-contain shrink-0 w-8 aspect-[1.1]"
                  />
                  <div className="my-auto">Swept path</div>
                </div>
                <div className="flex gap-10 items-center whitespace-nowrap max-md:max-w-full">
                  <div className="self-stretch my-auto tracking-normal">
                    PDF
                  </div>
                  <div className="self-stretch my-auto tracking-normal">
                    Drawing
                  </div>
                  <div className="flex items-center self-stretch text-sm leading-none bg-white rounded border border-solid border-zinc-300 text-neutral-500">
                    <div className="flex gap-3 justify-center items-center self-stretch px-4 py-3 my-auto">
                      <div className="self-stretch my-auto" onClick={openExportModal}>Export</div>
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/daf174a7aa6f4953b5da9876588ac6f2/94d1ef0e94cf0eb7ec52ac71d211d9a7f646ea3ea236d3f41657c680fb345bca?apiKey=daf174a7aa6f4953b5da9876588ac6f2&"
                        className="object-contain shrink-0 self-stretch my-auto w-2 aspect-[2]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col max-w-full text-md whitespace-nowrap text-neutral-700 w-[1600px]">
            <div className="flex flex-col w-full rounded-none max-w-[1600px] max-md:max-w-full">
              <div className="flex flex-wrap gap-5 justify-between px-12 py-3 w-full border-t border-b bg-slate-50 border-zinc-300 max-md:px-5 max-md:max-w-full">
                <div className="flex gap-6 my-auto tracking-normal">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/daf174a7aa6f4953b5da9876588ac6f2/b7ea18fdbbfd440999688a658e4664b8398e8cec4d8240b3810f0a0aebce5fb5?apiKey=daf174a7aa6f4953b5da9876588ac6f2&"
                    className="object-contain shrink-0 aspect-[0.83] w-8"
                  />
                  <div className="my-auto">Checklist</div>
                </div>
                <div className="flex gap-10 items-center max-md:max-w-full">
                  <div className="self-stretch my-auto tracking-normal">
                    PDF
                  </div>
                  <div className="self-stretch my-auto tracking-normal">
                    Document
                  </div>
                  <div className="flex items-center self-stretch text-sm leading-none bg-white rounded border border-solid border-zinc-300 text-neutral-500">
                    <div className="flex gap-3 justify-center items-center self-stretch px-4 py-3 my-auto">
                      <div className="self-stretch my-auto" onClick={openExportModal}>Export</div>
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/daf174a7aa6f4953b5da9876588ac6f2/94d1ef0e94cf0eb7ec52ac71d211d9a7f646ea3ea236d3f41657c680fb345bca?apiKey=daf174a7aa6f4953b5da9876588ac6f2&"
                        className="object-contain shrink-0 self-stretch my-auto w-2 aspect-[2]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col max-w-full text-md text-neutral-700 w-[1600px]">
            <div className="flex flex-col w-full rounded-none max-w-[1600px] max-md:max-w-full">
              <div className="flex flex-wrap gap-5 justify-between px-12 py-3 w-full border-t border-b bg-slate-50 border-zinc-300 max-md:px-5 max-md:max-w-full">
                <div className="flex gap-6 my-auto tracking-normal">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/daf174a7aa6f4953b5da9876588ac6f2/b7ea18fdbbfd440999688a658e4664b8398e8cec4d8240b3810f0a0aebce5fb5?apiKey=daf174a7aa6f4953b5da9876588ac6f2&"
                    className="object-contain shrink-0 aspect-[0.83] w-8"
                  />
                  <div className="my-auto basis-auto">Response sheet</div>
                </div>
                <div className="flex gap-10 items-center whitespace-nowrap max-md:max-w-full">
                  <div className="self-stretch my-auto tracking-normal">
                    PDF
                  </div>
                  <div className="self-stretch my-auto tracking-normal">
                    Document
                  </div>
                  <div className="flex items-center self-stretch text-sm leading-none bg-white rounded border border-solid border-zinc-300 text-neutral-500">
                    <div className="flex gap-3 justify-center items-center self-stretch px-4 py-3 my-auto">
                      <div className="self-stretch my-auto" onClick={openExportModal}>Export</div>
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/daf174a7aa6f4953b5da9876588ac6f2/94d1ef0e94cf0eb7ec52ac71d211d9a7f646ea3ea236d3f41657c680fb345bca?apiKey=daf174a7aa6f4953b5da9876588ac6f2&"
                        className="object-contain shrink-0 self-stretch my-auto w-2 aspect-[2]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col max-w-full text-md text-neutral-700 w-[1600px]">
            <div className="flex flex-col w-full rounded-none max-w-[1600px] max-md:max-w-full">
              <div className="flex flex-wrap gap-5 justify-between px-12 py-3 w-full border-t border-b bg-slate-50 border-zinc-300 max-md:px-5 max-md:max-w-full">
                <div className="flex gap-5 my-auto tracking-normal">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/daf174a7aa6f4953b5da9876588ac6f2/7c7d51099618794330519ea863998eaf2f3a12179a5839f7962aca110aebf4d3?apiKey=daf174a7aa6f4953b5da9876588ac6f2&"
                    className="object-contain shrink-0 w-8 aspect-[1.1]"
                  />
                  <div className="my-auto basis-auto">Cross section</div>
                </div>
                <div className="flex gap-10 items-center whitespace-nowrap max-md:max-w-full">
                  <div className="self-stretch my-auto tracking-normal">
                    PDF
                  </div>
                  <div className="self-stretch my-auto tracking-normal">
                    Drawing
                  </div>
                  <div className="flex items-center self-stretch text-sm leading-none bg-white rounded border border-solid border-zinc-300 text-neutral-500">
                    <div className="flex gap-3 justify-center items-center self-stretch px-4 py-3 my-auto">
                      <div className="self-stretch my-auto" onClick={openExportModal}>Export</div>
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/daf174a7aa6f4953b5da9876588ac6f2/94d1ef0e94cf0eb7ec52ac71d211d9a7f646ea3ea236d3f41657c680fb345bca?apiKey=daf174a7aa6f4953b5da9876588ac6f2&"
                        className="object-contain shrink-0 self-stretch my-auto w-2 aspect-[2]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col max-w-full text-md text-neutral-700 w-[1600px]">
            <div className="flex flex-col pb-5 w-full rounded-none max-w-[1600px] max-md:max-w-full" />
          </div>
        </div>
      </div>
  );

  const CompareDocument: React.FC = () => (
    <div className="space-y-8 px-6 py-4">         
          
          <div className="space-y-4">          
          <div className="grid grid-cols-4 gap-6 text-neutral-700">            
              <span>Current Submission</span>
              <span>Current Page</span>

              <span>Compare Submission</span>
              <span>Compare Page</span>
          </div>

          <div className="grid grid-cols-4 gap-6">
              
              <div className="items-center">
                <div className="grid grid-cols-1 gap-0">
                  <select
                    id="current-submission"
                    name="current-submission"
                    value={currentSubmission}
                    autoComplete="current-submission"
                    className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 font-medium text-neutral-700 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-emerald-600 sm:text-sm/6 px-5 py-3"
                  >
                    <option value={currentSubmission}>
                      {formatLabel('submission', currentSubmission)}
                    </option>
                  </select>
                  <img
                    src={ChevronBottomIcon}
                    alt="Chevron Bottom"
                    className="w-2 h-2 pointer-events-none col-start-1 row-start-1 mr-2 self-center justify-self-end text-gray-500"
                  />
                </div>
              </div>
        
              <div className="items-center">
                <div className="grid grid-cols-1 gap-0">
                  <select
                    id="current-page"
                    name="current-page"
                    value={currentPage}
                    autoComplete="page"
                    className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 font-medium text-neutral-700 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-emerald-600 sm:text-sm/6 px-5 py-3"
                  >                    
                    <option value={currentPage}>
                      {formatLabel('page', currentPage)}
                    </option>
                  </select>
                  <img
                    src={ChevronBottomIcon}
                    alt="Chevron Bottom"
                    className="w-2 h-2 pointer-events-none col-start-1 row-start-1 mr-2 self-center justify-self-end text-gray-500"
                  />
                </div>
              </div>

              <div className="items-center">
                <div className="grid grid-cols-1 gap-0">
                  <select
                    id="compare-submission"
                    name="compare-submission"
                    value={compareSubmission}
                    onChange={(e) => setCompareSubmission(e.target.value)}
                    autoComplete="compare-submission"
                    className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 font-medium text-neutral-700 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-emerald-600 sm:text-sm/6 px-5 py-3"
                  >
                    <option value="">Select Submission</option>
                    {compareSubmissions.map((sub) => (
                      <option key={sub} value={sub}>
                        {formatLabel('submission', sub)}
                      </option>
                    ))}
                  </select>
                  <img
                    src={ChevronBottomIcon}
                    alt="Chevron Bottom"
                    className="w-2 h-2 pointer-events-none col-start-1 row-start-1 mr-2 self-center justify-self-end text-gray-500"
                  />
                </div>
              </div>
        
              <div className="items-center">
                <div className="grid grid-cols-1 gap-0">
                  <select
                    id="compare-page"
                    name="compare-page"
                    value={comparePage}
                    onChange={(e) => setComparePage(e.target.value)}
                    autoComplete="compare-page"
                    className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 font-medium text-neutral-700 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-emerald-600 sm:text-sm/6 px-5 py-3"
                  >                    
                    <option value="">Select Page</option>
                    {comparePages.map((page) => (
                      <option key={page} value={page}>
                        {formatLabel('page', page)}
                      </option>
                    ))}
                  </select>
                  <img
                    src={ChevronBottomIcon}
                    alt="Chevron Bottom"
                    className="w-2 h-2 pointer-events-none col-start-1 row-start-1 mr-2 self-center justify-self-end text-gray-500"
                  />
                </div>
              </div>
            
            </div>
          </div>

          <div className="mt-2">
            {loading ? (
              <div className='items-center'>Loading...</div>
            ) : (
              <>
              { compareSubmission && comparePage &&(
              <div>  
              {/* <div className="items-center">
                <ReactCompareSlider handle={<ReactCompareSliderHandle style={{color: 'red'}}/>}
                  itemOne={<ReactCompareSliderImage src={leftImage} alt={`${currentSubmission +'-'+ currentPage}`} />}
                  itemTwo={<ReactCompareSliderImage src={rightImage} alt={`${compareSubmission +'-'+ comparePage}`} />}
                />
              </div> */}


<div style={{ position: "relative", height: "500px", width: "100%" }}>
      {/* Zoom and Pan Wrapper */}
      <TransformWrapper
        initialScale={1}
        minScale={1}
        maxScale={5}
        // wheel={{ step: 0.1 }}
        // doubleClick={{ step: 0.5 }}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            {/* Zoom Controls */}
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                zIndex: 10,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <button onClick={() => zoomIn()}>+</button>
              <button onClick={() => zoomOut()}>-</button>
              <button onClick={() => resetTransform()}>x</button>
            </div>

            {/* Select Buttons */}
            <div
              style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                zIndex: 10,
              }}
            >
              <button onClick={handleSelectLeft} style={{ padding: "5px 10px" }}>
                Select Left
              </button>
            </div>
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                zIndex: 10,
              }}
            >
              <button
                onClick={handleSelectRight}
                style={{ padding: "5px 10px" }}
              >
                Select Right
              </button>
            </div>

            {/* Close Button */}
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 10,
              }}
            >
              <button onClick={handleClose} style={{ padding: "5px 10px" }}>
                Close
              </button>
            </div>

            {/* Compare Slider */}
            <TransformComponent>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <ReactCompareSlider
                  itemOne={
                    <img
                      src={leftImage}
                      alt="Before"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  }
                  itemTwo={
                    <img
                      src={rightImage}
                      alt="After"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  }
                />
              </div>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </div>


              {/* <div className="items-center mt-2">
                <ReactCompareSlider
                  itemOne={<ReactCompareSliderImage src={leftImage} alt={`${currentSubmission +'-'+ currentPage}`} />}
                  itemTwo={<ReactCompareSliderImage src={rightImage} alt={`${compareSubmission +'-'+ comparePage}`} />}
                  portrait={true}
                />
              </div> */}
              </div>
              )}
              </>
            )}
          </div>
    </div>
  );


  const renderContent = () => {
    switch (activeTab) {
      case 'Add New Document':
        return <AddNewDocument />;
      case 'Export Document':
        return <ExportDocument />;
      case 'Compare Submissions':
        return <CompareDocument />;
      // Add cases for other tabs as needed
      default:
        return <div className='mt-8 ml-8'>Content not available.</div>;
    }
  };
  

  return (
    <>
      {keycloak.authenticated ? (
    <>
    <div className="flex flex-col">
      {/* Tab Headers */}
      <div className="flex flex-wrap gap-10 items-start pt-10 pr-20 pb-px pl-8 max-w-full text-md font-medium leading-none border-b bg-slate-50 border-zinc-300 text-neutral-500 w-[1600px] max-md:px-5 max-md:w-full">
        {tabs.map((tab) => (
          <div
            key={tab.name}
            onClick={() => setActiveTab(tab.name)}
            className={`cursor-pointer ${
              activeTab === tab.name
                ? 'flex flex-col font-semibold text-emerald-600'
                : 'text-neutral-500'
            }`}
          >
            <div className="self-center">{tab.name}</div>
            {activeTab === tab.name && (
              <div className="flex shrink-0 mt-3.5 h-0.5 bg-emerald-600" />
            )}
          </div>
        ))}
      </div>

      {/* Tab Content */}
      <div className="">
        {renderContent()}
      </div>
    </div>

    <ExportModal isOpen={isExportModalOpen} onClose={closeExportModal} />

    {/* <form className="flex flex-col items-start" onSubmit={handleSubmit}>
      <div className="self-stretch mt-7 w-full max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <InputField label="Document name" placeholder="Enter document name" />
          <SelectField label="Document type" options={["Select document type"]} />
        </div>
      </div>
      <div className="self-stretch mt-7 w-full max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <InputField label="Document category" placeholder="Enter document category" />
          <SelectField label="Document needed" options={["Mandatory"]} />
        </div>
      </div>
      <div
        className={`box-border flex relative flex-col shrink-0 ml-10 ${dragActive ? 'border-dashed border-emerald-500' : 'border-emerald-800'}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div className="mt-7 text-lg font-medium tracking-normal text-neutral-500 max-md:mt-10 max-md:max-w-full">
          Upload
        </div>
        <div className="flex justify-center items-center py-6 px-10 mt-4 mr-auto max-w-full rounded-2xl border border-dashed bg-[#F9FBFC] w-full max-md:px-5">
          <div className="flex gap-4 items-center max-md:flex-wrap">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/9c21eaadb9f35f9972215fa476f1f526a81fbb8c48d30d182bb4cf20b0521ec0?apiKey=0bfc8456e31a4c47a3987f9a6c2ca142&"
              alt=""
              className="shrink-0 self-stretch aspect-[0.81] w-[34px]"
            />
            <div className="self-stretch my-auto text-xl font-medium text-center text-emerald-800">
              {file ? file.name : 'Drag and Drop documents here or'}
            </div>
            <label className="self-stretch px-5 py-3 my-auto text-base font-semibold text-white whitespace-nowrap bg-emerald-600 rounded-md max-md:px-5 cursor-pointer">
              Upload
              <input type="file" className="hidden" onChange={handleFileUpload} />
            </label>
          </div>
        </div>
      </div>
      <div className="flex gap-5 justify-between mt-7 ml-10 text-lg font-medium tracking-normal">
        <button type="submit"  disabled={isProcessing} className="self-center px-5 py-3 h-auto text-white bg-emerald-600 rounded-lg max-md:px-5">
        {isProcessing ? 'Processing...' : 'Add Document'}
        </button>
        <button type="reset" className="self-center px-5 py-3 h-auto whitespace-nowrap bg-white rounded-lg border border-solid border-zinc-300 text-neutral-700 max-md:px-5" onClick={() => setFile(null)}>
          Reset
        </button>
      </div>
    </form>

    <div className="mt-20"></div> */}

    {/* <div className="mt-10">
    <h2 className="text-lg font-medium">Generated Images:</h2>
    <div className="flex flex-wrap gap-4 mt-4">
      {images.map((src, index) => (
        <img key={index} src={src} alt={`Page ${index + 1}`} className="w-48 h-auto rounded-lg border border-gray-300" />
      ))}
    </div>
    </div> */}
    </>
    ) : (
      <div>Please log in to upload documents.</div>
    )}
  </>
  );
};

export default DocumentForm;
