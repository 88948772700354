import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ExcalidrawImperativeAPI,
  BinaryFileData,
  DataURL,
  ExcalidrawProps,
} from "@excalidraw/excalidraw/types";
import { ExcalidrawElement, ExcalidrawImageElement, ExcalidrawAnnotationElement, FileId } from "@excalidraw/excalidraw/element/types";
import { Excalidraw } from "@excalidraw/excalidraw";
import { Footer } from "@excalidraw/excalidraw";
import "@excalidraw/excalidraw/index.css";
import type { OverlayModeFormData } from "@excalidraw/excalidraw/components/OverlayMode/OverlayMode";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { ImportedDataState } from "@excalidraw/excalidraw/data/types";
import ChatWidgetSelector from "./ChatWidgetSelector";

// Extend the ExcalidrawProps interface
interface CustomExcalidrawProps extends ExcalidrawProps {
  overlaymodeSelect?: {
    selectOptions1: { label: string; value: string }[];
    selectOptions2: { label: string; value: string }[];
  };
  onOverlayModeSubmit?: (formData: OverlayModeFormData) => void;
  submissions?: { id: string; elements: ExcalidrawElement[] }[];
}

export default function Playground() {
  const ExcalidrawComponent = Excalidraw as unknown as React.FC<
    CustomExcalidrawProps
  >;

  const location = useLocation();
  const navigate = useNavigate();
  const submissionKey = location.state?.submissionKey || localStorage.getItem('submission')  || "submission1";

  const pageString = location.state?.page || localStorage.getItem('page') || 'page1';

  // Extract the page index (zero-based index)
  const pageIndex = parseInt(pageString.replace('page', ''), 10) - 1;

  // Handle invalid page index
  const validPageIndex = isNaN(pageIndex) || pageIndex < 0 ? 0 : pageIndex;  

  const [excalidrawAPI, setExcalidrawAPI] =
    useState<ExcalidrawImperativeAPI | null>(null);
  const [images, setImages] = useState<string[]>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(validPageIndex);
 
  const [initialData, setInitialData] = useState<ImportedDataState | null>(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const imageElementsRef = useRef<{
    [key: number]: readonly ExcalidrawElement[];
  }>({});

  const imgComparisionUrl = process.env.REACT_APP_IMG_COMPARISON_BASE_URL;

  // Create refs for submissionKey and currentImageIndex
  const submissionKeyRef = useRef(submissionKey);
  const currentImageIndexRef = useRef(currentImageIndex);

  const [submissions, setSubmissions] = useState<{ id: string; elements: ExcalidrawElement[] }[]>([]);
  const [currentElements, setCurrentElements] = useState<readonly ExcalidrawElement[]>([]);

  const [selectedPage, setSelectedPage] = useState(validPageIndex); // Initialize with the first page

  const [isImageLoading, setIsImageLoading] = useState(true);

  const handlePageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    imageElementsRef.current = {};
    setIsImageLoading(true);
    const pageIndex = parseInt(event.target.value, 10);
    console.log("handlePageChange pageIndex", pageIndex);
    setSelectedPage(pageIndex);
    // handleImageLoad(pageIndex); // Assuming this function loads the selected image/page
  };

  // useEffect(() => {
  //   const initialSubmissions = getAllSubmissionElements();
  //   setSubmissions(initialSubmissions);
  // }, []);

  // Update refs when values change
  useEffect(() => {
    submissionKeyRef.current = submissionKey;
  }, [submissionKey]);

  useEffect(() => {
    currentImageIndexRef.current = currentImageIndex;
  }, [currentImageIndex]);

  const otherSubmissionKey =
    submissionKey === "submission2" ? "submission1" : "submission2";

  const selectOptions1 = [
    {
      label: `${submissionKey}-page${currentImageIndex + 1}`,
      value: `${submissionKey}-page${currentImageIndex + 1}`,
    },
  ];

  const selectOptions2 = Object.keys(localStorage)
    .filter((key) => key.startsWith(`${otherSubmissionKey}-page`))
    .map((key) => {
      return { label: key, value: key };
    });

  const overlaymodeSelect = {
    selectOptions1,
    selectOptions2,
  };

  localStorage.setItem("submission", submissionKey);

  const setCurrentPage = (index: number) => {
    localStorage.setItem("page", `page${index+1}`);
  }

  // Load images from S3 when submissionKey changes
  useEffect(() => {

    imageElementsRef.current = {};
    setIsInitialLoad(true);
    // Reset state when submissionKey changes
    setCurrentImageIndex(selectedPage);
    setCurrentPage(selectedPage);
    setInitialData(null);
    setImages([]);
    setIsImageLoading(true);

    const loadImagesFromS3 = async () => {
      if (submissionKey) {
        const imageUrls: string[] = [];
        let pageNo = 1;
        while (true) {
          const s3Url = localStorage.getItem(`${submissionKey}-page${pageNo}`);
          if (!s3Url) break;
          imageUrls.push(s3Url);
          pageNo++;
        }

        if (imageUrls.length > 0) {
          setImages(imageUrls);
          console.log(`Found ${imageUrls.length} images for ${submissionKey}`);

          try {
            const response = await fetch(imageUrls[selectedPage]);
            if (!response.ok) {
              throw new Error(`Failed to fetch image: ${response.statusText}`);
            }
            const blob = await response.blob();
            const dataUrl = await new Promise<string>((resolve) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result as string);
              reader.readAsDataURL(blob);
            });
            loadImage(dataUrl, selectedPage, submissionKey);
          } catch (error) {
            console.error("Error loading image from S3:", error);
            setIsImageLoading(false);
          }
        } else {
          console.error(
            `No images found in localStorage for submission key: ${submissionKey}`
          );
          setIsImageLoading(false);
        }
      }
    };

    loadImagesFromS3();
  }, [submissionKey, selectedPage]);

  // Use useEffect to update the scene when initialData changes
  useEffect(() => {
    if (excalidrawAPI && initialData) {
      console.log("Updating scene with new initial data");

      // Reset the scene first to avoid residual data
      excalidrawAPI.resetScene();

      // Convert files object to array
      const filesArray = Object.values(initialData.files ?? {});
      // Add files to the scene
      excalidrawAPI.addFiles(filesArray);

      console.log("initial elements",initialData.elements);
      // Update the elements
      excalidrawAPI.updateScene({
        elements: initialData.elements ?? [],
      });

      // const allElements = excalidrawAPI.getSceneElements();
      // console.log("All elements after update:", allElements);

      // Now set isInitialLoad to false
      setIsInitialLoad(false);  
    }
  }, [excalidrawAPI, initialData]);

  const saveToLocalStorage = (
    index: number,
    submissionKey: string,
    elements: readonly ExcalidrawElement[]
  ) => {
    console.log(
      `Saving to localStorage for ${submissionKey}_excalidrawData_${index}`,
      JSON.stringify(elements)
    );
    if (submissionKey) {
      localStorage.setItem(
        `${submissionKey}_excalidrawData_${index}`,
        JSON.stringify(elements)
      );
    }
  };

  const loadImage = async (
    dataURLString: string,
    index: number,
    submissionKey: string
  ) => {
    setIsImageLoading(true);
    
    return new Promise<void>((resolve) => {
      const fileId = `${submissionKey}_localImage${index}` as FileId;
      const dataURL = dataURLString as DataURL;

      const img = new Image();
      
      img.onload = () => {
        let elements: readonly ExcalidrawElement[] = [];

        const savedData = localStorage.getItem(
          `${submissionKey}_excalidrawData_${index}`
        );

        console.log("loadimage", `${submissionKey}_excalidrawData_${index}`, savedData);
        
        if (savedData) {
          try {
            const parsedData = JSON.parse(savedData);
            if (Array.isArray(parsedData) && parsedData.length > 0) {
              elements = parsedData;
            }
          } catch (error) {
            console.error("Error parsing saved data:", error);
          }
        }

        const newImageElementId = uuidv4();
        const imageElement: ExcalidrawImageElement = {
          type: "image",
          id: newImageElementId,
          fileId,
          status: "saved",
          x: 0,
          y: 0,
          width: img.width / 2,
          height: img.height / 2,
          isDeleted: false,
          fillStyle: "hachure",
          strokeWidth: 1,
          strokeStyle: "solid",
          roughness: 1,
          opacity: 100,
          angle: 0,
          version: 1,
          seed: Math.floor(Math.random() * 2 ** 31),
          versionNonce: 0,
          strokeColor: "#000000",
          backgroundColor: "transparent",
          roundness: null,
          groupIds: [],
          frameId: null,
          link: null,
          scale: [1, 1],
          locked: true,
          boundElements: null,
          updated: Date.now(),
          customData: {},
          isBinding: false,
          name: null,
        } as unknown as ExcalidrawImageElement;

        elements = [
          imageElement,
          ...elements.filter((el) => el.type !== "image"),
        ];

        imageElementsRef.current[index] = elements;

        const newInitialData: ImportedDataState = {
          files: {
            [fileId]: {
              id: fileId,
              dataURL,
              mimeType: "image/png",
              created: Date.now(),
            },
          },
          elements: elements,
        };

        setInitialData(newInitialData);
        setCurrentImageIndex(index);
        setCurrentPage(index);
        setIsImageLoading(false);

        saveToLocalStorage(index, submissionKey, elements);
        resolve();
      };

      img.onerror = () => {
        console.error("Error loading image");
        setIsImageLoading(false);
        resolve();
      };

      img.src = dataURLString;
    });
  };

  const handleImageLoad = async (index: number) => {
    if (images[index]) {
      try {
        const imageUrl = images[index];
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const dataUrl = await new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.readAsDataURL(blob);
        });
        await loadImage(dataUrl, index, submissionKey);
      } catch (error) {
        console.error("Error loading image from S3:", error);
        setIsImageLoading(false);
      }
    }
  };

  const onChange = (
    elements: readonly ExcalidrawElement[],
    appState: any
  ) => {
    if (isInitialLoad) {
      console.log("Initial load, skipping onChange");
      return;
    }

    console.log(
      "onChange called with submissionKey:",
      submissionKeyRef.current,
      "currentImageIndex:",
      currentImageIndexRef.current,
      "elements",
      elements
    );
    imageElementsRef.current[currentImageIndexRef.current] = elements;
    saveToLocalStorage(
      currentImageIndexRef.current,
      submissionKeyRef.current,
      elements
    );
    // Update currentElements state
    setCurrentElements(elements);
  };

  // Include the handleOverlayModeSubmit function
  const handleOverlayModeSubmit = async (formData: OverlayModeFormData) => {
    console.log("Overlay mode form data:", formData);

    const formDataToSend = new FormData();

    const file_1_url = localStorage.getItem(formData.currentSubmission);
    const file_2_url = localStorage.getItem(formData.comparisonSubmission);

    try {
      // Fetch the images from S3
      const [file1Response, file2Response] = await Promise.all([
        fetch(file_1_url as RequestInfo),
        fetch(file_2_url as RequestInfo),
      ]);

      // Convert the responses to Blob objects
      const file1Blob = await file1Response.blob();
      const file2Blob = await file2Response.blob();

      // Append the Blob objects to the FormData
      formDataToSend.append(
        "file1",
        file1Blob,
        `${formData.currentSubmission}.png`
      );
      formDataToSend.append(
        "file2",
        file2Blob,
        `${formData.comparisonSubmission}.png`
      );
      formDataToSend.append("color1", "0,0,255");
      formDataToSend.append("color2", "255,0,0");

      // Send the FormData using axios
      const response = await axios.post(
        `${imgComparisionUrl}/api/upload_png/`,
        formDataToSend
      );
      console.log("Success:", response.data);

      // Extract the updated image from the response
      const updatedImage = response.data.results[0].images.find(
        (img: any) => img.type === "Comb"
      ).image;

      // Generate a unique ID for the new image element and file
      const newFileId = uuidv4() as FileId;
      const newDataURL = `data:image/png;base64,${updatedImage}` as DataURL;

      const newFileData: BinaryFileData = {
        id: newFileId,
        dataURL: newDataURL,
        mimeType: "image/png",
        created: Date.now(),
      };

      const newImageElementId = uuidv4(); // Element IDs are strings
      const newImageElement: ExcalidrawImageElement = {
        type: "image",
        id: newImageElementId,
        fileId: newFileId,
        status: "saved",
        x: 0,
        y: 0,
        width: 1920,
        height: 1080,
        isDeleted: false,
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        angle: 0,
        version: 1,
        seed: Math.floor(Math.random() * 2 ** 31),
        versionNonce: 0,
        strokeColor: "#000000",
        backgroundColor: "transparent",
        roundness: null,
        groupIds: [],
        frameId: null,
        link: null,
        scale: [1, 1],
        locked: true,
        boundElements: null,
        updated: Date.now(),
        customData: {},
        isBinding: false,
        name: null,
      } as unknown as ExcalidrawImageElement;

      if (excalidrawAPI) {
        const currentElements = excalidrawAPI.getSceneElements();
        const updatedElements = [...currentElements, newImageElement];

        // Add the new file (wrap in an array)
        excalidrawAPI.addFiles([newFileData]);

        // Update the elements
        excalidrawAPI.updateScene({
          elements: updatedElements,
        });

        // Save updated elements to localStorage
        saveToLocalStorage(currentImageIndex, submissionKey, updatedElements);
      }
    } catch (error: any) {
      console.error("Error in handleOverlayModeSubmit:", error);
    }
  };

  // Function to collect all submission elements
  // Helper function to get all elements for a submission
  const getAllElementsForSubmission = (submissionKey: string): ExcalidrawElement[] => {
    let index = 0;
    let elements: ExcalidrawElement[] = [];

    while (true) {
      const storageKey = `${submissionKey}_excalidrawData_${index}`;
      const savedData = localStorage.getItem(storageKey);
      if (!savedData) break;

      try {
        const parsedData = JSON.parse(savedData);
        if (Array.isArray(parsedData)) {
          elements = elements.concat(parsedData);
        }
      } catch (error) {
        console.error("Error parsing saved data for", storageKey, error);
      }

      index++;
    }

    return elements;
  };

  // useEffect to update submissions
  useEffect(() => {
    if (!submissionKeyRef.current) return;

    const updatedElements = getAllElementsForSubmission(submissionKeyRef.current);

    setSubmissions((prevSubmissions) => {
      const otherSubmissions = prevSubmissions.filter(
        (sub) => sub.id !== submissionKeyRef.current
      );

      const updatedSubmission = {
        id: submissionKeyRef.current,
        elements: updatedElements,
      };

      return [...otherSubmissions, updatedSubmission];
    });
  }, [currentElements, submissionKey]);
  

  return (
    <>
      {isImageLoading || !initialData ? (
        <div className="flex items-center justify-center mt-5">Loading...</div>
      ) : (
        <ExcalidrawComponent
          // key={`${submissionKey}-${selectedPage}`}
          excalidrawAPI={(api) => setExcalidrawAPI(api)}
          initialData={initialData}
          onChange={onChange}
          overlaymodeSelect={overlaymodeSelect}
          onOverlayModeSubmit={handleOverlayModeSubmit}
          submissions={submissions}
        >
          <Footer>
            <div
              className="Stack Stack_horizontal ml-auto mr-[0.6em]"
              style={{ gap: 6 }}
            >

              {/* <ChatWidgetSelector /> */}

              {/* Dropdown for Page Selection */}
              <select
                value={selectedPage}
                onChange={handlePageChange}
                className="custom-dropdown bg-white shadow-md rounded-md px-3 py-1"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid rgba(0,0,0,0.1)',
                  padding: '0.5em',
                }}
              >
                {images.map((_, index) => (
                  <option key={index} value={index}>
                    Page {index + 1}
                  </option>
                ))}
              </select>
            </div>
          </Footer>
        </ExcalidrawComponent>
      )}
    </>
  );
}
