import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import ChevronBottomIcon from '../assets/icons/chevron-bottom.svg';

interface ExportModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ExportModal: React.FC<ExportModalProps> = ({ isOpen, onClose }) => {

  const options = [
    "Export with all comments",
    "Export with response sheet as doc file",
    "Export only resolved comments",
    "Export only pending comments",
    "Export only open comments",
    "Export only draft comments",
    "Export only specific division comments or those by selected individuals"
  ];

  // Define the type for the checked options state
  type CheckedOptions = {
    [key: string]: boolean;
  };

  // Function to initialize all checkboxes to false
  const initializeCheckedOptions = (): CheckedOptions => {
    return options.reduce((acc: CheckedOptions, option: string) => {
      acc[option] = false;
      return acc;
    }, {});
  };

  // Initialize state to track checked status of each checkbox
  const [checkedOptions, setCheckedOptions] = useState<CheckedOptions>(
    initializeCheckedOptions()
  );

  // Handler for checkbox changes with explicit type annotations
  const handleCheckboxChange = (option: string) => {
    setCheckedOptions((prev) => {
      const newChecked: CheckedOptions = { ...prev, [option]: !prev[option] };

      // If "Export with all comments" is checked, disable specific options
      if (option === "Export with all comments" && newChecked[option]) {
        const optionsToDisable: string[] = [
          "Export only pending comments",
          "Export only open comments",
          "Export only draft comments",
          "Export only specific division comments or those by selected individuals"
        ];

        optionsToDisable.forEach((opt) => {
          newChecked[opt] = false;
        });
      }

      return newChecked;
    });
  };

  // Handler to reset all checkboxes to their initial state
  const handleReset = () => {
    setCheckedOptions(initializeCheckedOptions());
  };

  // Determine if "Export with all comments" is checked
  const isAllCommentsChecked: boolean = checkedOptions["Export with all comments"];

  // Define options to disable when "Export with all comments" is checked
  const optionsToDisable: string[] = [
    "Export only pending comments",
    "Export only open comments",
    "Export only draft comments",
    "Export only specific division comments or those by selected individuals"
  ];

  const [currentSubmission, setCurrentSubmission] = useState<string>('');
  const [availablePages, setAvailablePages] = useState<number[]>([]);
  const [selectedPage, setSelectedPage] = useState<string>('all');

  useEffect(() => {
    const submission = localStorage.getItem('submission');
    const page = localStorage.getItem('page');

    if (submission) {
      setCurrentSubmission(submission);
      findAvailablePages(submission);
    }

    if (page) {
      setSelectedPage(page);
    }
  }, []);

  const findAvailablePages = (submission: string): void => {
    const pages: number[] = [];
    let pageIndex = 0;

    while (true) {
      const key = `${submission}_excalidrawData_${pageIndex}`;
      if (localStorage.getItem(key) === null) {
        break;
      }
      pages.push(pageIndex);
      pageIndex++;
    }

    setAvailablePages(pages);
  };

  const handlePageChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const selectedValue = e.target.value;
    setSelectedPage(selectedValue);
    // localStorage.setItem('page', selectedValue);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Advanced Export" size="semi-full">
      

        {/* Main Content */}
        <div className="space-y-8 px-6">
          
          {/* Export Type Selection */}
          <div className="space-y-4">
          {/* Title Section */}
          <div className="grid grid-cols-3 gap-6 text-neutral-700">            
              <span>Export as</span>
              <span>Pages</span>
          </div>

          {/* Buttons Section */}
          <div className="grid grid-cols-3 gap-6">
              {/* First Button */}
              <div className="items-center">
                <div className="grid grid-cols-1 gap-0">
                  <select
                    id="export-as"
                    name="export-as"
                    autoComplete="export-as"
                    className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 font-medium text-neutral-700 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-emerald-600 sm:text-sm/6 px-5 py-3"
                  >
                    <option>PDF format</option>
                  </select>
                  <img
                    src={ChevronBottomIcon}
                    alt="Chevron Bottom"
                    className="w-2 h-2 pointer-events-none col-start-1 row-start-1 mr-2 self-center justify-self-end text-gray-500"
                  />
                </div>
              </div>

              {/* Second Button */}
              <div className="items-center">
                <div className="grid grid-cols-1 gap-0">
                  <select
                    id="pages"
                    name="pages"
                    value={selectedPage}
                    onChange={handlePageChange}
                    autoComplete="pages"
                    className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 font-medium text-neutral-700 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-emerald-600 sm:text-sm/6 px-5 py-3"
                  >
                    <option value="all">All Pages</option>
                    {availablePages.map((pageNum) => (
                      <option key={pageNum} value={pageNum}>
                        Page {pageNum + 1}
                      </option>
                    ))}
                  </select>
                  <img
                    src={ChevronBottomIcon}
                    alt="Chevron Bottom"
                    className="w-2 h-2 pointer-events-none col-start-1 row-start-1 mr-2 self-center justify-self-end text-gray-500"
                  />
                </div>
              </div>
            
            </div>
          </div>


          {/* Export Options Grid */}
          {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              "Export with all comments",
              "Export with response sheet as doc file",
              "Export only resolved comments",
              "Export only pending comments",
              "Export only open comments",
              "Export only draft comments",
              "Export only specific division comments or those by selected individuals"
            ].map((option, index) => (
              <div
                  key={index}
                  className={`flex items-start gap-3 ${
                    option.length > 50 ? 'col-span-1 md:col-span-2 lg:col-span-3' : ''
                  }`}
                >
                <div className="w-5 h-5 border-2 border-neutral-300 rounded flex-shrink-0" />
                <span className="text-neutral-700 break-words">
                  {option}
                </span>
              </div>
            ))}
          </div> */}

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {options.map((option, index) => {
              // Generate a unique identifier for each checkbox
              const checkboxId = `export-option-${index}`;

              // Determine if this checkbox should be disabled
              const isDisabled = isAllCommentsChecked && optionsToDisable.includes(option);

              return (
                <div
                  key={index}
                  className={`flex items-start gap-3 ${
                    option.length > 50 ? 'col-span-1 md:col-span-2 lg:col-span-3' : ''
                  }`}
                >
                  {/* Custom Checkbox Component */}
                  <div className="flex h-6 shrink-0 items-center">
                    <div className="group grid size-4 grid-cols-1">
                      <input
                        checked={checkedOptions[option]}
                        onChange={() => handleCheckboxChange(option)}
                        id={checkboxId}
                        name={checkboxId}
                        type="checkbox"
                        aria-describedby={`${checkboxId}-description`}
                        disabled={isDisabled}
                        className={`col-start-1 row-start-1 appearance-none rounded border ${
                          isDisabled ? 'border-gray-300 bg-gray-100 cursor-not-allowed' : 'border-gray-300 bg-white'
                        } checked:border-emerald-600 checked:bg-emerald-600 indeterminate:border-emerald-600 indeterminate:bg-emerald-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 disabled:cursor-not-allowed forced-colors:appearance-auto`}
                      />
                      <svg
                        fill="none"
                        viewBox="0 0 14 14"
                        className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
                      >
                        <path
                          d="M3 8L6 11L11 3.5"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="opacity-0 group-has-[:checked]:opacity-100"
                        />
                        <path
                          d="M3 7H11"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="opacity-0 group-has-[:indeterminate]:opacity-100"
                        />
                      </svg>
                    </div>
                  </div>

                  {/* Label and Description */}
                  <div className="text-md">
                    <label htmlFor={checkboxId} className={`cursor-pointer ${
                        isDisabled ? 'text-neutral-500 cursor-not-allowed' : 'text-neutral-700'
                      }`}
                    >
                      {option}
                    </label>
                    {/* Optional Description - Adjust if needed */}
                    {/* <p id={`${checkboxId}-description`} className="text-gray-500">
                      Get notified when someone posts a comment on a posting.
                    </p> */}
                  </div>
                </div>
              );
            })}
          </div>

          {/* Action Buttons */}
          <div className="flex gap-4 pt-8">
            <button className="px-8 py-3 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors">
              Export
            </button>
            <button className="px-8 py-3 bg-white text-neutral-700 border border-zinc-300 rounded-lg hover:bg-gray-50 transition-colors"
            onClick={handleReset}
            >
              Reset
            </button>
          </div>
        </div>
      
    </Modal>
  );
};

export default ExportModal;