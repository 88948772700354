import React, { ReactNode, useEffect, useRef } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: ReactNode;
  size?: 'small' | 'medium' | 'large' | 'extra-large' | 'semi-full' | 'full'; // New size prop
}

const sizeClasses: Record<
  NonNullable<ModalProps['size']>,
  string
> = {
  small: 'max-w-sm',
  medium: 'max-w-md',
  large: 'max-w-lg',
  'extra-large': 'max-w-2xl',  
  'semi-full': 'max-w-[75%]',
  full: 'max-w-full',
};

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  size = 'medium', // Default size
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const previouslyFocusedElement = useRef<HTMLElement | null>(null);

  // Close modal on Escape key press
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isOpen) {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  // Prevent scrolling when modal is open
  useEffect(() => {
    if (isOpen) {
      previouslyFocusedElement.current = document.activeElement as HTMLElement;
      document.body.style.overflow = 'hidden';
      // Focus the modal
      modalRef.current?.focus();
    } else {
      document.body.style.overflow = 'auto';
      // Return focus to the previously focused element
      previouslyFocusedElement.current?.focus();
    }
  }, [isOpen]);

  // Focus trap within the modal
  useEffect(() => {
    const handleTab = (e: KeyboardEvent) => {
      if (!isOpen) return;

      const focusableElements = modalRef.current?.querySelectorAll<HTMLElement>(
        'a[href], button, textarea, input, select, [tabindex]:not([tabindex="-1"])'
      );

      if (!focusableElements || focusableElements.length === 0) return;

      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      if (e.key === 'Tab') {
        if (e.shiftKey) { // Shift + Tab
          if (document.activeElement === firstElement) {
            e.preventDefault();
            lastElement.focus();
          }
        } else { // Tab
          if (document.activeElement === lastElement) {
            e.preventDefault();
            firstElement.focus();
          }
        }
      }
    };

    document.addEventListener('keydown', handleTab);
    return () => {
      document.removeEventListener('keydown', handleTab);
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    // Overlay
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose} // Close modal when clicking on the overlay
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      {/* Modal Container */}
      <div
        className={`bg-white rounded-lg shadow-lg w-full ${sizeClasses[size]} mx-4 focus:outline-none`}
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
        ref={modalRef}
        tabIndex={-1} // Make div focusable
      >
        {/* Modal Header */}
        <div className="flex justify-between items-center p-5 border-b">
          {title && (
            <h2 id="modal-title" className="text-2xl font-semibold text-neutral-700">
              {title}
            </h2>
          )}
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
            aria-label="Close modal"
          >
            {/* Close Icon (X) */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        {/* Modal Body */}
        <div id="modal-description" className="p-4">
          {children}
        </div>
        {/* Modal Footer (Optional) */}
        {/*
        <div className="flex justify-end p-4 border-t">
          <button onClick={onClose} className="px-4 py-2 bg-gray-300 rounded">Cancel</button>
          <button className="ml-2 px-4 py-2 bg-blue-600 text-white rounded">Confirm</button>
        </div>
        */}
      </div>
    </div>
  );
};

export default Modal;
